import React, {Component} from 'react'
import styled from 'styled-components'
import DialogModal from 'editoria-common/src/DialogModal'
import PropTypes from 'prop-types'
import {Select, Button, Section} from '@pubsweet/ui'
import {map, find, filter} from "lodash"

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const InnerWrapper = styled.div`

`

const getOptions = (templates, target) => {
  return map(filter(templates, {target}), ({id, name}) => ({
    value : id,
    label : name,
    target
  }))
}

class LinkTemplateModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error : false,
      shouldWarn : false,
      templates : [],
      selected : {
        pagedjs : null,
        epub : null,
        vivliostyle : null
      }
    }
  }

  async componentDidMount() {
    await Promise.all([this.fetchLinkedTemplates(), this.fetchGlobalTemplates()])
  }

  async fetchLinkedTemplates() {
    const {data} = this.props
    const {getLinkedTemplates, bookId} = data

    const {data : {getTemplates : linkedTemplates}} = await getLinkedTemplates(bookId)

    this.setState({
      linkedTemplates,
    })
  }

  async fetchGlobalTemplates() {
    const {data} = this.props
    const {getTemplates} = data

    await this.fetchLinkedTemplates()
    const {data : {getTemplates : globalTemplates}} = await getTemplates()

    this.setState({
      globalTemplates
    })
  }

  onSelectChange(target, selection) {
    const {selected} = this.state

    this.setState({
      selected : {...selected, [target] : selection}
    })
  }

  async onLinkTemplate(target) {
    const {data} = this.props
    const {linkTemplate, bookId} = data
    const {selected} = this.state

    const {value : id} = selected[target]

    await linkTemplate({id, bookId})

    this.setState({
      selected : {...selected, [target] : undefined}
    })

    await this.fetchLinkedTemplates()
  }

  async onUnlinkTemplate(id) {
    const {data} = this.props
    const {unlinkTemplate} = data

    await unlinkTemplate({id})
    await this.fetchLinkedTemplates()
  }

  async onEditTemplate(id) {
    this.props.data.onEditTemplate(id)
  }

  renderBody() {
    const {linkedTemplates, globalTemplates, selected} = this.state

    return (
      <OuterWrapper>
        <InnerWrapper>
          {
            ['pagedjs', 'epub', 'vivliostyle'].map(target => {
              const template = find(linkedTemplates, {target})

              return <Section>
                <p>{target}</p>
                {
                  template ?
                    <React.Fragment>
                      <p>{template.name}</p>
                      <Button
                        primary
                        onClick={() => this.onUnlinkTemplate(template.id)}
                      >
                        Unlink
                      </Button>
                      <Button
                        primary
                        onClick={() => this.onEditTemplate(template.id)}
                      >
                        Edit Meta
                      </Button>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Select
                        isClearable={false}
                        isDisabled={false}
                        isLoading={false}
                        isSearchable={false}
                        onChange={selection => this.onSelectChange(target, selection)}
                        options={getOptions(globalTemplates, target)}
                        value={selected[target]}
                      />
                      <Button
                        primary
                        disabled={!selected[target]}
                        onClick={() => this.onLinkTemplate(target)}
                      >
                        Link
                      </Button>
                    </React.Fragment>
                }
              </Section>
            })
          }
        </InnerWrapper>
      </OuterWrapper>
    )
  }

  render() {
    const {isOpen, hideModal} = this.props

    return (
      <DialogModal
        headerText="Link Template"
        isOpen={isOpen}
        notCentered
        onRequestClose={hideModal}
        showConfirmButton={false}
        size="medium"
        textCancel="Close"
      >
        {this.renderBody()}
      </DialogModal>
    )
  }
}

LinkTemplateModal.propTypes = {
  data : PropTypes.shape({
    bookId : PropTypes.string.isRequired,
    getLinkedTemplates : PropTypes.func.isRequired,
    getTemplates : PropTypes.func.isRequired,
    linkTemplate : PropTypes.func.isRequired,
    unlinkTemplate : PropTypes.func.isRequired,
  })
}

export default LinkTemplateModal
