import React from 'react'
import { Mutation } from '@apollo/react-components'
import gql from 'graphql-tag'

const ADD_TAG = gql`
  mutation AddTag($id: ID!, $tag: String!) {
    addTag(id: $id, tag: $tag)
  }
`

const addTagMutation = props => {
  const { render } = props

  return (
    <Mutation mutation={ADD_TAG}>
      {(addTag, addTagResult) =>
        render({
          addTag,
          addTagResult,
        })
      }
    </Mutation>
  )
}

export default addTagMutation
