import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import styled from 'styled-components'
import 'codemirror/mode/css/css'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/lib/codemirror.css'
import {Controlled as CodeMirror} from 'react-codemirror2'

const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  padding: 8px;
`
const CodeEditorWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  height: 100%;
  max-width: 50%;
  width: 50%;
`
const EditorToolbar = styled.div`
  display: flex;
  height: 5%;
  justify-content: flex-end;
`
const Actions = styled.button`
  background: none;
  border: none;
  color: #0d78f2;
  cursor: pointer;
  font: inherit;
  margin-right: 20px;
  outline: inherit;
  padding: 0;
`
const EditorArea = styled.div`
  flex-grow: 1;
  height: 95%;
  .react-codemirror2 {
    height: ${props => 100 / props.numEditors}%;
    padding-bottom: 10px;
    border-bottom: 1px solid grey;
    .CodeMirror {
      height: 100%;
    }
  }
`
const PreviewArea = styled.div`
  height: 100%;
  max-width: 50%;
  width: 50%;
  iframe {
    height: 100%;
    width: 100%;
  }
`

const handlePrint = e => {
  e.preventDefault()
  const pri = document.getElementById('printBook').contentWindow
  pri.focus()
  pri.print()
}

const handleDownload = hashed => e => {
  e.preventDefault()
  axios.get(`/api/fileserver/paged/${hashed}/index.html`).then(res => {
    window.location.replace(res.request.responseURL)
  })
}

const PagedStyler = ({
  bookId,
  bookTitle,
  hashed,
  history,
  template,
  onWarningModal,
  rules,
  allowJs
}) => {
  const [cssText, setCssText] = useState()
  const [jsText, setJsText] = useState()
  const [random, setRandom] = useState('')

  const {id} = template
  const {canLinkTemplate} = rules

  const templateCss = template.files.find(file => file.mimetype === 'text/css')
  const templateJs = allowJs && template.files.find(file => file.mimetype === 'application/javascript')

  useEffect(() => {
    async function fetchData() {
      const cssResponse = await fetch(
        `/uploads/paged/${hashed}/${templateCss.name}.${templateCss.extension}`,
      )
      setCssText(await cssResponse.text())
      if (templateJs) {
        const jsResponse = await fetch(
          `/uploads/paged/${hashed}/${templateJs.name}.${templateJs.extension}`,
        )
        setJsText(await jsResponse.text())
      }
    }

    fetchData()
  }, [hashed, id])

  const previewerUrlParams = new URLSearchParams({
    url: `/uploads/paged/${hashed}/index.html`,
    stylesheet: `/uploads/paged/${hashed}/${templateCss.name}.${templateCss.extension}`,
    js: templateJs ? `/uploads/paged/${hashed}/${templateJs.name}.${templateJs.extension}` : ''
  })

  return (
    <Wrapper>
      <CodeEditorWrapper>
        <EditorToolbar>
          <Actions
            onClick={() =>
              onWarningModal(
                bookId,
                bookTitle,
                templateCss,
                cssText,
                jsText,
                template,
                hashed,
                history,
                canLinkTemplate
              ).then(() =>
                setRandom(
                  Math.random()
                    .toString(36)
                    .substring(7),
                ),
              )
            }
          >
            Save
          </Actions>
          <Actions onClick={handlePrint}>Print</Actions>
          <Actions onClick={handleDownload(hashed)}>Download HTML</Actions>
        </EditorToolbar>
        <EditorArea numEditors={allowJs ? 2 : 1}>
          <CodeMirror
            onBeforeChange={(editor, data, newValue) => {
              setCssText(newValue)
            }}
            options={{
              mode : 'css',
              lineWrapping : true,
              lineNumbers : true,
              readOnly : false,
            }}
            value={cssText}
          />
          {allowJs && <CodeMirror
            onBeforeChange={(editor, data, newValue) => {
              setJsText(newValue)
            }}
            options={{
              mode : 'javascript',
              lineWrapping : true,
              lineNumbers : true,
              readOnly : false,
            }}
            value={jsText}
          />}
        </EditorArea>
      </CodeEditorWrapper>
      <PreviewArea>
        <iframe
          frameBorder="0"
          id="printBook"
          key={random}
          src={`/paged/previewer/index.html?${previewerUrlParams.toString()}`}
          title="PagedJS"
        />
      </PreviewArea>
    </Wrapper>
  )
}

PagedStyler.propTypes = {
  bookId: PropTypes.string.isRequired,
  bookTitle: PropTypes.string.isRequired,
  hashed: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
  onWarningModal: PropTypes.func.isRequired,
  allowJs: PropTypes.bool
}

export default PagedStyler
