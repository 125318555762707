import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'
import { hot } from 'react-hot-loader'

import createHistory from 'history/createBrowserHistory'

import { Root } from 'pubsweet-client'

// Modals
import AssetManager from 'editoria-asset-manager/src/ConnectedAssetManager'
import ModalProvider from 'editoria-common/src/ModalProvider'
import AddBookModal from 'pubsweet-component-editoria-dashboard/src/modals/AddBookModal'
import CloneBookModal from 'pubsweet-component-editoria-dashboard/src/modals/CloneBookModal'
import AddEndNoteModal from 'pubsweet-component-bookbuilder/src/ui/src/modals/AddEndNoteModal'
import DeleteBookModal from 'pubsweet-component-editoria-dashboard/src/modals/DeleteBookModal'
import ArchiveBookModal from 'pubsweet-component-editoria-dashboard/src/modals/ArchiveBookModal'
import DeleteBookComponentModal from 'pubsweet-component-bookbuilder/src/ui/src/modals/DeleteBookComponentModal'
import BookTeamManagerModal from 'pubsweet-component-bookbuilder/src/TeamManager/ConnectedTeamManager'
import WarningModal from 'pubsweet-component-bookbuilder/src/ui/src/modals/WarningModal'
import WarningPagedJs from 'pubsweet-component-bookbuilder/src/PagedStyler/WarningModal'
import ErrorModal from 'pubsweet-component-bookbuilder/src/ui/src/modals/ErrorModal'
import UnlockModal from 'pubsweet-component-bookbuilder/src/ui/src/modals/UnlockModal'
import ExportBookModal from 'pubsweet-component-bookbuilder/src/ui/src/modals/ExportBookModal'
import MetadataModal from 'pubsweet-component-bookbuilder/src/ui/src/modals/MetadataModal'
import WorkflowModal from 'pubsweet-component-bookbuilder/src/ui/src/modals/WorkflowModal'
import LinkTemplateModal from 'pubsweet-component-bookbuilder/src/ui/src/modals/LinkTemplateModal'
import BookSettingsModal from 'pubsweet-component-bookbuilder/src/ui/src/modals/BookSettingsModal'
import UnlockedModal from 'pubsweet-component-wax/src/modals/UnlockedModal'
import CreateTemplateModal from 'pubsweet-component-editoria-templates/src/ui/src/modals/TemplateModal'
import UpdateTemplateModal from 'pubsweet-component-editoria-templates/src/ui/src/modals/ConnectedUpdateTemplateModal'
import DeleteTemplateModal from 'pubsweet-component-editoria-templates/src/ui/src/modals/DeleteTemplateModal'

import theme from './theme'

import routes from './routes'

const history = createHistory()
const modals = {
  addBook: AddBookModal,
  cloneBook: CloneBookModal,
  assetManagerModal: AssetManager,
  assetManagerEditor: AssetManager,
  addEndNote: AddEndNoteModal,
  deleteBook: DeleteBookModal,
  archiveBook: ArchiveBookModal,
  deleteBookComponent: DeleteBookComponentModal,
  bookTeamManager: BookTeamManagerModal,
  warningModal: WarningModal,
  warningPagedJs: WarningPagedJs,
  unlockModal: UnlockModal,
  metadataModal: MetadataModal,
  workflowModal: WorkflowModal,
  errorModal: ErrorModal,
  unlockedModal: UnlockedModal,
  createTemplateModal: CreateTemplateModal,
  updateTemplateModal: UpdateTemplateModal,
  deleteTemplateModal: DeleteTemplateModal,
  bookSettingsModal: BookSettingsModal,
  exportBookModal: ExportBookModal,
  linkTemplateModal: LinkTemplateModal,
}
const rootEl = document.getElementById('root')

ReactDOM.render(
  <ModalProvider modals={modals}>
    <Root history={history} routes={routes} theme={theme} />
  </ModalProvider>,
  rootEl,
)

export default hot(module)(Root)
