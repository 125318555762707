import React from 'react'
import { Mutation } from '@apollo/react-components'
import gql from 'graphql-tag'

const LINK_TEMPLATE = gql`
  mutation LinkTemplate($input: LinkTemplateInput!) {
    linkTemplate(input: $input) {
      id,
      name
    }
  }
`

const linkTemplateMutation = props => {
  const { render } = props

  return (
    <Mutation mutation={LINK_TEMPLATE}>
      {(linkTemplate, linkTemplateResult) =>
        render({
          linkTemplate,
          linkTemplateResult,
        })
      }
    </Mutation>
  )
}

export default linkTemplateMutation
