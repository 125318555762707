import React from 'react'
import { Mutation } from '@apollo/react-components'
import gql from 'graphql-tag'

const UPDATE_LINKED_TEMPLATE = gql`
  mutation UpdateLinkedTemplate($input: UpdateTemplateInput!) {
    updateTemplate(input: $input) {
      id
      files {
        name
      }
    }
  }
`

const updateLinkedTemplateMutation = props => {
  const { render } = props

  return (
    <Mutation mutation={UPDATE_LINKED_TEMPLATE}>
      {(updateLinkedTemplate, updateTemplateResult) =>
        render({ updateLinkedTemplate, updateTemplateResult })
      }
    </Mutation>
  )
}

export default updateLinkedTemplateMutation
