import React from 'react'
import { Mutation } from '@apollo/react-components'
import gql from 'graphql-tag'

const UNLINK_TEMPLATE = gql`
  mutation UnlinkTemplate($id: ID!) {
    unlinkTemplate(id: $id)
  }
`

const unlinkTemplateMutation = props => {
  const { render } = props

  return (
    <Mutation mutation={UNLINK_TEMPLATE}>
      {(unlinkTemplate, unlinkTemplateResult) =>
        render({
          unlinkTemplate,
          unlinkTemplateResult,
        })
      }
    </Mutation>
  )
}

export default unlinkTemplateMutation
