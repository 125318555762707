import React from 'react'
import { Mutation } from '@apollo/react-components'
import gql from 'graphql-tag'

const CLONE_BOOK = gql`
  mutation cloneBook($id: ID!, $title: String!, $isVersion: Boolean) {
    cloneBook(id: $id, title: $title, isVersion: $isVersion) {
      id
    }
  }
`

const cloneBookMutation = props => {
  const { render } = props

  return (
    <Mutation mutation={CLONE_BOOK}>
      {(cloneBook, cloneBookResult) =>
        render({ cloneBook, cloneBookResult })
      }
    </Mutation>
  )
}

export default cloneBookMutation