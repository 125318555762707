/* eslint-disable no-console */

import React from 'react'
import { get } from 'lodash'
import { adopt } from 'react-adopt'
import { withRouter } from 'react-router-dom'
import {
  getTemplateQuery,
  cloneTemplateMutation,
} from 'pubsweet-component-editoria-templates/src/queries'

import withModal from 'editoria-common/src/withModal'
import PagedStyler from './PagedStyler'
import statefull from '../Statefull'
import {
  updateTemplateFilesMutation,
  getBookQuery,
  templateUpdatedForPagedStyledSubscription,
  getBookBuilderRulesQuery,
} from '../queries'

const config = require('config')
const allowJs = config.pagedjs &&  config.pagedjs.allowJs

const mapper = {
  statefull,
  withModal,
  getTemplateQuery,
  getBookQuery,
  cloneTemplateMutation,
  updateTemplateFilesMutation,
  templateUpdatedForPagedStyledSubscription,
  getBookBuilderRulesQuery
}


const mapProps = args => ({
  state: args.statefull.state,
  setState: args.statefull.setState,
  template : get(args.getTemplateQuery, 'data.getTemplate'),
  book : get(args.getBookQuery, 'data.getBook'),
  cloneTemplate : args.cloneTemplateMutation.cloneTemplate,
  updateTemplateFiles : args.updateTemplateFilesMutation.updateTemplateFiles,
  loadingBook : args.getBookQuery.networkStatus === 1,
  loading : args.getTemplateQuery.networkStatus === 1,
  refetching :
    args.getTemplateQuery.networkStatus === 4 ||
    args.getTemplateQuery.networkStatus === 2, // possible apollo bug
  loadingRules : args.getBookBuilderRulesQuery.networkStatus === 1,
  rules : get(args.getBookBuilderRulesQuery, 'data.getBookBuilderRules'),
  onWarningModal : (
    bookId,
    bookTitle,
    cssFile,
    cssText,
    jsText,
    template,
    hashed,
    history,
    canLinkTemplate
  ) => {
    const {
      cloneTemplateMutation,
      updateTemplateFilesMutation,
      withModal,
    } = args
    const { cloneTemplate } = cloneTemplateMutation
    const { updateTemplateFiles } = updateTemplateFilesMutation
    const { showModal, hideModal } = withModal
    return new Promise((resolve, reject) => {
      let input = {
        bookId,
        id: template.id,
        hashed,
        cssText
      }

      if (allowJs && jsText) {
        input.jsText = jsText
      }

      const saveCssBook = () => {
        cloneTemplate({
          variables: {
            input: {
              ...input,
              name: `${template.name}-${bookTitle}`
            },
          },
        }).then(res => {
          const { data } = res
          const { cloneTemplate } = data
          const { path } = cloneTemplate
          history.replace(`/books/${bookId}/pagedPreviewer/paged/${path}`)
          hideModal()
          resolve()
        })
      }

      const saveCssAllBook = async () => {
        const { data } = await updateTemplateFiles({
          variables: {
            input,
          },
        })

        const { path } = data.updateTemplateFiles
        history.replace(`/books/${bookId}/pagedPreviewer/paged/${path}`)
        hideModal()
        resolve()
      }

      showModal('warningPagedJs', {
        saveCssBook,
        saveCssAllBook,
        canLinkTemplate,
        template
      })
    })
  },
})

const Composed = adopt(mapper, mapProps)

const Connected = props => {
  const {
    match: {
      params: { hashed, templateId, id },
    },
    history,
  } = props
  return (
    <Composed bookId={id} templateId={templateId}>
      {({
          book,
          template,
          onWarningModal,
          loading,
          loadingBook,
          loadingRules,
          rules
        }) => {

        if (loading || loadingBook || loadingRules) return <p>Loading ...</p>

        return (
          <PagedStyler
            bookId={book.id}
            bookTitle={book.title}
            hashed={hashed}
            history={history}
            onWarningModal={onWarningModal}
            template={template}
            rules={rules}
            allowJs={allowJs}
          />
        )
      }}
    </Composed>
  )
}

export default withRouter(Connected)
