import {isEmpty, find} from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Book from './Book'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const BookList = props => {
  const {
    books,
    refetching,
    refetchingRules,
    renameBook,
    remove,
    archiveBook,
    onDeleteBook,
    onArchiveBook,
    onCloneBook,
    bookRules,
    canAddBooks
  } = props

  if (refetching || refetchingRules) return 'Refetching...'

  if (isEmpty(books)) {
    return <div>There are no books to display.</div>
  }

  const bookWithVersions = []

  for (const book of books) {
    if (!find(books, {id : book.referenceId})) {
      book.versions = books.filter(version => version.referenceId === book.id)
      bookWithVersions.push(book)
    }
  }

  return (
    <Wrapper>
      {bookWithVersions.map(book => (
        <React.Fragment>
          <Book
            archiveBook={archiveBook}
            book={book}
            key={book.id}
            remove={remove}
            renameBook={renameBook}
            onDeleteBook={onDeleteBook}
            onArchiveBook={onArchiveBook}
            onCloneBook={onCloneBook}
            bookRule={bookRules.find(bookRule => bookRule.id === book.id)}
            canAddBooks={canAddBooks}
          />
          {book.versions.map(book => (
            <Book
              archiveBook={archiveBook}
              book={book}
              key={book.id}
              remove={remove}
              renameBook={renameBook}
              onDeleteBook={onDeleteBook}
              onArchiveBook={onArchiveBook}
              onCloneBook={onCloneBook}
              bookRule={bookRules.find(bookRule => bookRule.id === book.id)}
              isVersion={true}
              canAddBooks={canAddBooks}
            />
          ))}
        </React.Fragment>
      ))}
    </Wrapper>
  )
}

BookList.propTypes = {
  books: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  container: PropTypes.any.isRequired,
  remove: PropTypes.func.isRequired,
  renameBook: PropTypes.func.isRequired,
}

export default BookList
