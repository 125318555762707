import React from 'react'
import { ApolloConsumer } from '@apollo/react-components'
import gql from 'graphql-tag'

const GET_LINKED_TEMPLATES = gql`
  query GetLinkedTemplates($bookId: ID) {
    getTemplates(bookId: $bookId) {
      id
      name
      bookId
      target
    }
  }
`

const getLinkedTemplatesQuery = props => {
  const { render } = props
  return (
    <ApolloConsumer>
      {client => render({ client, query: GET_LINKED_TEMPLATES })}
    </ApolloConsumer>
  )
}

export { GET_LINKED_TEMPLATES }
export default getLinkedTemplatesQuery
