import React from 'react'
import { Mutation } from '@apollo/react-components'
import gql from 'graphql-tag'

const REMOVE_TAG = gql`
  mutation RemoveTag($id: ID!, $tag: String!) {
    removeTag(id: $id, tag: $tag)
  }
`

const removeTagMutation = props => {
  const { render } = props

  return (
    <Mutation mutation={REMOVE_TAG}>
      {(removeTag, removeTagResult) =>
        render({
          removeTag,
          removeTagResult,
        })
      }
    </Mutation>
  )
}

export default removeTagMutation
