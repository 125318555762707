import React from 'react'
import { ButtonWithIcon } from './Button'

const TeamManagerButton = ({ onClick, label }) => {
  const icon = (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Common/Icon/Team">
        <g id="Common/Icon-background">
          <rect width="28" height="28" fill="white" />
        </g>
        <path
          id="Base"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.4 12.2222C19.4 11.7324 18.9968 11.3333 18.5 11.3333C18.0032 11.3333 17.6 11.7324 17.6 12.2222C17.6 12.712 18.0032 13.1111 18.5 13.1111C18.9968 13.1111 19.4 12.712 19.4 12.2222ZM21.2 12.2222C21.2 13.6924 19.9886 14.8889 18.5 14.8889C17.0114 14.8889 15.8 13.6924 15.8 12.2222C15.8 10.752 17.0114 9.55556 18.5 9.55556C19.9886 9.55556 21.2 10.752 21.2 12.2222ZM13.1 9.55556C13.1 8.57511 12.2927 7.77778 11.3 7.77778C10.3073 7.77778 9.5 8.57511 9.5 9.55556C9.5 10.536 10.3073 11.3333 11.3 11.3333C12.2927 11.3333 13.1 10.536 13.1 9.55556ZM14.9 9.55556C14.9 11.5164 13.2854 13.1111 11.3 13.1111C9.3146 13.1111 7.7 11.5164 7.7 9.55556C7.7 7.59467 9.3146 6 11.3 6C13.2854 6 14.9 7.59467 14.9 9.55556ZM15.746 16.7076C16.5281 16.1102 17.4911 15.7778 18.5 15.7778C20.9813 15.7778 23 17.7716 23 20.2222C23 20.7129 22.5977 21.1111 22.1 21.1111C21.6023 21.1111 21.2 20.7129 21.2 20.2222C21.2 18.752 19.9886 17.5556 18.5 17.5556C17.8853 17.5556 17.3012 17.7636 16.8296 18.1324C17.3192 19.0178 17.6 20.0329 17.6 21.1111C17.6 21.6018 17.1977 22 16.7 22C16.2023 22 15.8 21.6018 15.8 21.1111C15.8 18.6604 13.7813 16.6667 11.3 16.6667C8.8187 16.6667 6.8 18.6604 6.8 21.1111C6.8 21.6018 6.3977 22 5.9 22C5.4023 22 5 21.6018 5 21.1111C5 17.68 7.8269 14.8889 11.3 14.8889C13.0343 14.8889 14.6057 15.5849 15.746 16.7076Z"
          fill="#828282"
        />
      </g>
    </svg>
  )
  return <ButtonWithIcon onClick={onClick} label={label} icon={icon} />
}
export default TeamManagerButton
