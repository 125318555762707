import React from 'react'
import { Mutation } from '@apollo/react-components'
import gql from 'graphql-tag'

const UPDATE_TEMPLATE_FILES = gql`
  mutation UpdateTemplateFiles($input: UpdateTemplateFilesInput!) {
    updateTemplateFiles(input: $input) {
      path
      validationResult
    }
  }
`

const updateTemplateFilesMutation = props => {
  const { render } = props

  return (
    <Mutation mutation={UPDATE_TEMPLATE_FILES}>
      {(updateTemplateFiles, updateTemplateFilesResult) =>
        render({ updateTemplateFiles, updateTemplateFilesResult })
      }
    </Mutation>
  )
}

export default updateTemplateFilesMutation
